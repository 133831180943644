.onboarding-modal.modal-Wrapse {
    margin: 0 auto;
    background: #e3ede2;
    border-radius: 15px;
    box-shadow: 0 3px 5px 2px rgb(0 0 0 / 15%);
    /* text-align: center; */
}
.onboarding-modal .PrivateSwitchBase-root-1 {
    padding: 0;
    margin: 0 6px 0 0;
}
.onboarding-modal .react-select-dropdown-prefix__control {
    height: 34px;
    min-height: 34px;
}
.onboarding-modal .react-select-dropdown-prefix__indicator {
    color: #353535;
    right: -11px;
}
.onboarding-modal .modal-content {
    margin: 0;
    /* padding: 30px; */
}
.onboarding-modal .content {
    padding: 0;
}
.onboarding-modal ol{padding: 0 0 0 17px;}
.onboarding-modal ol li {
    list-style: disc;
}
.onboarding-modal .react-select-dropdown {
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 4px;
    padding: 0 15px;
    min-width: 65px;
}
.modal-Wrapse .drop-down .css-2b097c-container {
    width: 100%;
    max-width: 100px;
    /* margin: 0 auto; */
    text-align: left;
}
.modal-Wrapse .content h3 {
    margin-bottom: 20px;
}
.modal-Wrapse .content p, .modal-Wrapse .content span {
    font-size: 13px;
}
.modal-Wrapse .content .check-box{
    margin:3px 0;
}
.onboarding-modal  svg{width:16px;}
.onboarding-modal .react-select-dropdown-prefix__value-container > div {
    width: 100%;
    margin: 0;
    padding: 0;
}
.onboarding-modal .modal-header {
    align-items: center;
    justify-content: space-between;
}
.onboarding-modal .modal-header i {
    border: 2px solid;
    padding: 5px 10px;
    border-radius: 50%;
    margin-right: 10px;
}
.onboarding-modal .drop-down {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width:991px){
    .onboarding-modal.modal-Wrapse{width: 95%;}
    .onboarding-modal .modal-content {
        width: 95%;
        margin: auto;
        padding: 30px;
    }
    .onboarding-modal .close-Modse > a {
        right: 0;
    }
}