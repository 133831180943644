.mid-Mainsec {
    .notification_filter {
        label {
            margin-bottom: 0;
        }
        .clear_all {
            font-size: 12px;
            text-decoration: underline;
            text-align: right;
            color: #58aa55;
            cursor: pointer;
        }
        .filter-options {
            .form-control {
                width: inherit;
                margin: 3px;
            }
            .primary-Gbtn {
                padding: 10px;
                margin: 3px;
            }
        }
        .notification-list {
            margin-top: 20px;
            height: inherit;
            max-height: 500px;
            &.notifi_detail {
                background-color: #f7f7f7;
                border: 1px solid #eee;
                padding: 8px;
                li {
                    padding: 15px;
                    border: 0;
                    &.unread-msg{
                        background-color: #dcdcdc; 
                    }
                    &:last-child {
                        margin-bottom: 0 !important;
                    }
                    .img-pr {
                        img { 
                            width: auto;
                            max-width: 140px;
                            max-height: 108px;
                        }
                    }
                    .unread-msg {
                        &:after {
                            display: none;
                        }
                    }
                }
            }
            .list-group-item {
                padding: 0;
            }
            .notification-list-user-block {
                font-size: 15px;
                .img-pr {
                    max-width: 200px;
                    min-height: 85px;
                    overflow: hidden;
                    border-radius: 0;
                    @media (max-width: 767px) {
                        height: auto;
                    }
                    img {
                        width: 100%;
                    }
                }
                .notification_summ {
                    @media (max-width: 767px) {
                        width: 100%;
                        padding: 0;
                    }
                    .notification-body {
                        transition: all ease-in-out 0.5s;
                        -webkit-transition: all ease-in-out 0.5s;
                        -moz-transition: all ease-in-out 0.5s;
                        word-break: break-all;
                        span {
                            font-size: 12px;
                            color: #ff0000;
                            transition: all ease-in-out 0.5s;
                            -webkit-transition: all ease-in-out 0.5s;
                            -moz-transition: all ease-in-out 0.5s;
                        }
                    }
                }
                .notification-detail {
                    margin-left: 0px;
                    padding: 0;
                    @media (max-width: 767px) {
                        padding: 10px 0;
                    }
                }
            }
        }
    }
}
.inbox-empty {
    p {
        font-size: 14px;
        text-transform: uppercase;
    }
}

.loader_text {
    width: 100%;
    height: 40px;
    text-align: center;
    position: relative;
  
    span {
      left: 50%;
      top: 50%;
      text-transform: capitalize;
      position: absolute;
      overflow: hidden;
      transform: translate(-50%, -50%);
  
      &:before {
        color: #aaa;
        content: attr(data-loading-text);
      }
  
      &:after {
        top: 0;
        left: 0;
        width: 0;
        opacity: 1;
        color: #444;
        overflow: hidden;
        position: absolute;
        content: attr(data-loading-text);
        animation: loading 5s infinite;
      }
    }
  }
