.main-maintenance-content {
    padding: 100px 30px;
    max-width: 600px;
}

.main-div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-heading {
    font-size: 30px;
    color: #fee13b;
}

.maintenance-content {
    font-size: 15px;
}

label {
    text-transform: uppercase;
    font-size: 14px;
}

.active-maintenance {
    color: #fee13b;
}

.react-switch-handle {
    background-color: #fee13b !important;
}
